import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { alpha } from '@mui/material/styles';
import { CustomTooltip, formatXAxis } from './ChartUtils';

const ElectricityFlowChart = ({ data, theme }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: theme ? alpha(theme.palette.background.paper, 0.9) : 'inherit' }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme ? theme.palette.secondary.main : 'inherit', fontWeight: 'medium' }}>
        Electricity Flow (MW)
      </Typography>
      <Box sx={{ height: 250 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={theme ? alpha(theme.palette.text.secondary, 0.2) : 'inherit'} />
            <XAxis dataKey="datetime" tickFormatter={formatXAxis} stroke={theme ? theme.palette.text.secondary : 'inherit'} />
            <YAxis stroke={theme ? theme.palette.text.secondary : 'inherit'} domain={[0, 'auto']} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="electricity_to_battery" name="To Battery" stroke="#66bb6a" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="battery_discharge" name="From Battery" stroke="#26a69a" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="electricity_to_electrolyzer" name="To Electrolyzer" stroke="#7e57c2" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default ElectricityFlowChart;