import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function SystemCapacityChart({ data, theme }) {
  if (!data) return null;

  const chartData = {
    labels: ['Wind', 'Solar', 'Battery', 'Electrolyzer', 'H2 Storage'],
    datasets: [{
      data: [
        data.wind_capacity || 0,
        data.solar_capacity || 0,
        data.battery_capacity || 0,
        data.electrolyzer_capacity || 0,
        data.h2_storage_capacity || 0
      ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
      ],
    }],
  };

  return <Pie data={chartData} />;
}

export default SystemCapacityChart;