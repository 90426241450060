import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function SummaryTable({ data, theme }) {
  console.log("Data in SummaryTable:", JSON.stringify(data, null, 2));

  if (!data || Object.keys(data).length === 0) {
    console.log("No data provided to SummaryTable");
    return null;
  }

  const rows = [
    { name: 'Hydrogen Storage Capacity', value: `${data.hydrogen_storage_cap?.toFixed(2) || data.h2_storage_capacity?.toFixed(2) || 'N/A'} kg` },
    { name: 'Site ID', value: data.site_id || 'N/A' },
    { name: 'Solar Capacity', value: `${data.solar_cap?.toFixed(2) || data.solar_capacity?.toFixed(2) || 'N/A'} MW` },
    { name: 'Total Cost', value: `$${data.total_cost?.toFixed(2) || 'N/A'}` },
    { name: 'Wind Capacity', value: `${data.wind_cap?.toFixed(2) || data.wind_capacity?.toFixed(2) || 'N/A'} MW` },
    { name: 'Battery Capacity', value: `${data.battery_cap?.toFixed(2) || data.battery_capacity?.toFixed(2) || 'N/A'} MWh` },
    { name: 'Cost per kg', value: `$${data.cost_per_kg?.toFixed(2) || 'N/A'}/kg` },
    { name: 'Electrolyzer Capacity', value: `${data.electrolyzer_cap?.toFixed(2) || data.electrolyzer_capacity?.toFixed(2) || 'N/A'} MW` },
  ];

  return (
    <TableContainer component={Paper} elevation={3} sx={{ mb: 4, borderRadius: 2, overflow: 'hidden' }}>
      <Typography variant="h6" sx={{ p: 2, backgroundColor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>
        System Summary
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Metric</StyledTableCell>
            <StyledTableCell align="right">Value</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SummaryTable;