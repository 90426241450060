import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProjectLocation } from './ProjectLocationContext';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box, Typography, Button, Paper, Drawer, List, ListItem, ListItemText, ListItemIcon, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Divider, IconButton, Menu, MenuItem, Tooltip, Grid, Card, CardContent, CardActions, AppBar, Toolbar, alpha } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LandscapeIcon from '@mui/icons-material/Landscape';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PowerPlantOptimizer from './PowerPlantOptimizer.jsx';
import LandUseAnalyser from './LandUseAnalyser';
import { Logo } from './theme';  // Ensure correct import path

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
console.log("Mapbox token:", process.env.REACT_APP_MAPBOX_ACCESS_TOKEN);

const theme = createTheme({
  palette: {
    primary: { main: '#2E8B57' },
    secondary: { main: '#4CAF50' },
    background: { default: '#f0f8ff' },
  },
});

const StyledLogo = styled(Logo)(({ theme }) => ({
  // Add any additional styles here if needed
}));

function Hydrosage() {
  const [location, setLocation] = useState({ lng: -97.3114, lat: 38.4749 });
  const [zoom, setZoom] = useState(3.56);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { setProjectLocation } = useProjectLocation();
  const navigate = useNavigate();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markerRef = useRef(null);
  const [currentStep, setCurrentStep] = useState('location');
  const [formData, setFormData] = useState({
    year: 2023,
    onshore_wind_cost: 1500,
    solar_pv_cost: 1100,
    battery_storage_cost: 200,
    electrolyzer_cost: 1200,
    h2_storage_cost: 600,
    debt_cost: 7,
    equity_cost: 12,
    debt_equity_ratio: 70,
    hydrogen_demand: 1000,
    grid_power_limit: 5
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [projectMenuAnchor, setProjectMenuAnchor] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isEditProjectDialogOpen, setIsEditProjectDialogOpen] = useState(false);
  const [editProjectName, setEditProjectName] = useState('');
  const [mapInitialized, setMapInitialized] = useState(false);
  const analysisOptionsRef = useRef(null);
  const [results, setResults] = useState(null);
  const resultsRef = useRef(null);

  const initializeMap = useCallback(() => {
    if (mapContainer.current && !map.current) {
      try {
        console.log("Initializing map...");
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/satellite-streets-v11',
          center: [-97.3114, 38.4749], // Center of US
          zoom: 3.56
        });
        
        map.current.on('load', () => {
          setMapInitialized(true);
          console.log("Map initialized:", map.current);
        });

        map.current.on('click', (e) => {
          const newLocation = {
            lng: e.lngLat.lng.toFixed(4),
            lat: e.lngLat.lat.toFixed(4)
          };
          setSelectedLocation(newLocation);

          if (markerRef.current) markerRef.current.remove();
          markerRef.current = new mapboxgl.Marker()
            .setLngLat([newLocation.lng, newLocation.lat])
            .addTo(map.current);
        });

      } catch (error) {
        console.error("Error initializing map:", error);
      }
    }
  }, []);

  useEffect(() => {
    // Load projects from local storage
    const savedProjects = JSON.parse(localStorage.getItem('hydrosageProjects')) || [];
    setProjects(savedProjects);
  }, []);

  useEffect(() => {
    // Save projects to local storage whenever they change
    localStorage.setItem('hydrosageProjects', JSON.stringify(projects));
  }, [projects]);

  useEffect(() => {
    console.log("Current step:", currentStep);
    console.log("Map container:", mapContainer.current);
    console.log("Map instance:", map.current);

    if (currentStep === 'location' && mapContainer.current && !map.current) {
      initializeMap();
    }

    return () => {
      if (map.current && currentStep !== 'location') {
        console.log("Removing map...");
        map.current.remove();
        map.current = null;
        setMapInitialized(false);
      }
    };
  }, [currentStep, initializeMap]);

  const handleNewProject = () => {
    setIsNewProjectDialogOpen(true);
    // Reset map when creating a new project
    if (map.current) {
      map.current.remove();
      map.current = null;
      setMapInitialized(false);
    }
  };

  const handleCreateProject = () => {
    if (newProjectName) {
      const newProject = {
        id: Date.now(),
        name: newProjectName,
        location: null,
        formData: { ...formData },
        currentStep: 'location'
      };
      setProjects([...projects, newProject]);
      setCurrentProject(newProject);
      setIsNewProjectDialogOpen(false);
      setNewProjectName('');
      setCurrentStep('location');
      // Initialize map after creating a new project
      setTimeout(initializeMap, 0);
    }
  };

  const handleSelectProject = (project) => {
    setCurrentProject(project);
    setSelectedLocation(project.location);
    setFormData(project.formData);
    setCurrentStep(project.currentStep);
  };

  const handleLandUseAnalysis = () => {
    console.log("handleLandUseAnalysis called");
    if (currentProject && currentProject.location) {
      console.log("Setting project location for land use analysis:", currentProject.location);
      setProjectLocation(currentProject.location);
      updateCurrentProject('landUse');
    } else {
      console.error("No location selected for land use analysis");
      alert("Please select a location before proceeding to Land Use Analysis.");
    }
  };

  const handleSystemDesign = () => {
    console.log("handleSystemDesign called");
    if (currentProject && currentProject.location) {
      console.log("Updating current project to system design");
      updateCurrentProject('systemDesign');
    } else {
      console.error("No location selected for system design");
      alert("Please select a location before proceeding to System Design.");
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: parseFloat(value) || 0
    }));
    updateCurrentProject(currentStep, { ...formData, [name]: parseFloat(value) || 0 });
  };

  const handleSubmit = async (event) => {
    console.log('handleSubmit function called');
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    console.log('NODE_ENV:', process.env.NODE_ENV);
    const timestamp = new Date().getTime();
    const apiUrl = process.env.NODE_ENV === 'development' 
      ? `https://api.greenh2.ai/optimize?ts=${timestamp}`
      : `https://api.greenh2.ai/optimize?ts=${timestamp}`;
    console.log('API URL:', apiUrl);

    const requestData = {
      year: parseInt(formData.year),
      onshore_wind_cost: parseFloat(formData.onshore_wind_cost),
      solar_pv_cost: parseFloat(formData.solar_pv_cost),
      battery_storage_cost: parseFloat(formData.battery_storage_cost),
      electrolyzer_cost: parseFloat(formData.electrolyzer_cost),
      h2_storage_cost: parseFloat(formData.h2_storage_cost),
      debt_cost: parseFloat(formData.debt_cost),
      equity_cost: parseFloat(formData.equity_cost),
      debt_equity_ratio: parseFloat(formData.debt_equity_ratio),
      hydrogen_demand: parseFloat(formData.hydrogen_demand),
      grid_power_limit: parseFloat(formData.grid_power_limit)
    };

    if (currentProject && currentProject.location) {
      requestData.latitude = parseFloat(currentProject.location.lat);
      requestData.longitude = parseFloat(currentProject.location.lng);
    } else {
      console.error('No location data available');
      setError('Please select a location before optimizing.');
      setIsLoading(false);
      return;
    }

    console.log('Submitting form data:', JSON.stringify(requestData, null, 2));

    try {
      console.log('Sending API request to:', apiUrl);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      });

      console.log('API response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('API Error Response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received data from API:', data);
      setResults(data);
      console.log('Results set successfully');
      // Scroll to results after they're set
      setTimeout(() => {
        resultsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (error) {
      console.error('Error in API call:', error);
      if (error instanceof TypeError && error.message.includes('network')) {
        setError('Network error. Please check your internet connection and try again.');
      } else if (error.message.includes('HTTP error')) {
        setError(`API request failed: ${error.message}`);
      } else {
        setError(`An unexpected error occurred: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
      console.log('Form submission process completed');
    }
  };

  const updateCurrentProject = (step, newFormData = formData, newLocation = null) => {
    console.log("updateCurrentProject called with step:", step);
    if (currentProject) {
      const updatedProject = {
        ...currentProject,
        currentStep: step,
        formData: newFormData,
        location: newLocation || currentProject.location
      };
      console.log("Updating current project:", updatedProject);
      setProjects(projects.map(p => p.id === currentProject.id ? updatedProject : p));
      setCurrentProject(updatedProject);
      setCurrentStep(step);
    }
  };

  const handleProjectMenuOpen = (event, projectId) => {
    setProjectMenuAnchor(event.currentTarget);
    setSelectedProjectId(projectId);
  };

  const handleProjectMenuClose = () => {
    setProjectMenuAnchor(null);
    setSelectedProjectId(null);
  };

  const handleEditProject = () => {
    const projectToEdit = projects.find(p => p.id === selectedProjectId);
    setEditProjectName(projectToEdit.name);
    setIsEditProjectDialogOpen(true);
    handleProjectMenuClose();
  };

  const handleSaveEditProject = () => {
    setProjects(projects.map(p => 
      p.id === selectedProjectId ? { ...p, name: editProjectName } : p
    ));
    setIsEditProjectDialogOpen(false);
  };

  const handleDeleteProject = () => {
    setProjects(projects.filter(p => p.id !== selectedProjectId));
    if (currentProject && currentProject.id === selectedProjectId) {
      setCurrentProject(null);
    }
    handleProjectMenuClose();
  };

  const handleDuplicateProject = () => {
    const projectToDuplicate = projects.find(p => p.id === selectedProjectId);
    const newProject = {
      ...projectToDuplicate,
      id: Date.now(),
      name: `${projectToDuplicate.name} (Copy)`,
    };
    setProjects([...projects, newProject]);
    handleProjectMenuClose();
  };

  const renderProjectCards = () => (
    <Grid container spacing={2}>
      {projects.map((project) => (
        <Grid item xs={12} sm={6} md={4} key={project.id}>
          <Card>
            <CardContent>
              <Typography variant="h6">{project.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                Lat: {project.location?.lat}, Lon: {project.location?.lng}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Status: {project.currentStep}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleSelectProject(project)}>Open</Button>
              <IconButton 
                size="small" 
                onClick={(event) => handleProjectMenuOpen(event, project.id)}
                aria-label="project options"
              >
                <MoreVertIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const updateProjectLocation = (location) => {
    setProjectLocation(location);
    if (currentProject) {
      const updatedProject = { ...currentProject, location };
      setCurrentProject(updatedProject);
      setProjects(projects.map(p => p.id === updatedProject.id ? updatedProject : p));
    }
  };

  const handleConfirmLocation = () => {
    if (selectedLocation && currentProject) {
      updateProjectLocation(selectedLocation);
      updateCurrentProject('analysis', formData, selectedLocation);
      // Scroll to analysis options after a short delay
      setTimeout(() => {
        analysisOptionsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      alert("Please select a location on the map before confirming.");
    }
  };

  const renderContent = () => {
    console.log("Rendering content for step:", currentStep);
    console.log("Current project:", currentProject);
    if (!currentProject) {
      return (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>My Projects</Typography>
          {renderProjectCards()}
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', pt: 8 }}>
        {currentStep !== 'systemDesign' && currentStep !== 'landUse' && (
          <>
            <Typography variant="h5" gutterBottom>Select Project Location</Typography>
            <Box 
              ref={mapContainer} 
              sx={{ 
                height: '60vh', 
                width: '100%',
                borderRadius: 4, 
                overflow: 'hidden', 
                mb: 2,
                border: '1px solid #ccc',
              }}
            />
            {mapInitialized && selectedLocation && currentStep === 'location' && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleConfirmLocation}
                >
                  Confirm Location
                </Button>
              </Box>
            )}
          </>
        )}
        {currentStep === 'analysis' && (
          <Box ref={analysisOptionsRef} sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h5" gutterBottom>Choose Analysis Type</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 4 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleLandUseAnalysis} 
                sx={{ width: '200px', height: '100px' }}
              >
                Land Use Analysis
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleSystemDesign} 
                sx={{ width: '200px', height: '100px' }}
              >
                System Design
              </Button>
            </Box>
          </Box>
        )}
        {currentStep === 'landUse' && (
          <LandUseAnalyser key={currentProject.id} />
        )}
        {currentStep === 'systemDesign' && (
          <Box sx={{ mt: 4, pt: 8 }}>
            {currentProject && currentProject.location ? (
              <PowerPlantOptimizer
                initialFormData={formData}
                selectedLocation={currentProject.location}
                theme={theme}
              />
            ) : (
              <Typography variant="h6" color="error">
                Error: No location selected. Please go back and select a location.
              </Typography>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: alpha(theme.palette.primary.main, 0.9),
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StyledLogo />
            </Box>
            <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>
              Hydrosage
            </Typography>
          </Toolbar>
        </AppBar>

        <Box sx={{ display: 'flex', flexGrow: 1, mt: 8 }}>
          <Drawer
            variant="permanent"
            sx={{
              width: 280,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 280,
                boxSizing: 'border-box',
                backgroundColor: alpha(theme.palette.background.paper, 0.9),
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                zIndex: (theme) => theme.zIndex.drawer,
              },
            }}
          >
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%', pt: 12 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '80px'
              }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleNewProject}
                  sx={{ 
                    width: '80%',
                    height: '50px'
                  }}
                >
                  New Project
                </Button>
              </Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                Projects
              </Typography>
              <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                <ListItem 
                  button 
                  onClick={() => setCurrentProject(null)}
                  selected={!currentProject}
                  sx={{
                    borderRadius: 1,
                    mb: 1,
                    '&.Mui-selected': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    },
                  }}
                >
                  <ListItemIcon><DashboardIcon color="primary" /></ListItemIcon>
                  <ListItemText primary="All Projects" />
                </ListItem>
                {projects.map((project) => (
                  <ListItem 
                    button 
                    key={project.id} 
                    onClick={() => handleSelectProject(project)}
                    selected={currentProject && currentProject.id === project.id}
                    sx={{
                      borderRadius: 1,
                      mb: 1,
                      '&.Mui-selected': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      },
                    }}
                  >
                    <ListItemIcon><FolderIcon color="primary" /></ListItemIcon>
                    <ListItemText primary={project.name} />
                    <IconButton 
                      size="small" 
                      onClick={(event) => handleProjectMenuOpen(event, project.id)}
                      sx={{ ml: 1 }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              {currentProject && (
                <Paper elevation={3} sx={{ p: 2, mt: 0, backgroundColor: alpha(theme.palette.background.paper, 0.7) }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Current Project: {currentProject.name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1, mt: 2 }}>
                    Project Stages
                  </Typography>
                  <List>
                    <ListItem 
                      button 
                      onClick={() => setCurrentStep('location')} 
                      selected={currentStep === 'location'}
                      sx={{
                        borderRadius: 1,
                        mb: 1,
                        '&.Mui-selected': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        },
                      }}
                    >
                      <ListItemIcon><LocationOnIcon color="primary" /></ListItemIcon>
                      <ListItemText primary="1. Select Location" />
                    </ListItem>
                    <ListItem 
                      button 
                      onClick={() => setCurrentStep('analysis')} 
                      selected={currentStep === 'analysis'}
                      disabled={!currentProject.location}
                      sx={{
                        borderRadius: 1,
                        mb: 1,
                        '&.Mui-selected': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        },
                      }}
                    >
                      <ListItemIcon><DashboardIcon color="primary" /></ListItemIcon>
                      <ListItemText primary="2. Choose Analysis" />
                    </ListItem>
                    <ListItem 
                      button 
                      onClick={() => setCurrentStep(currentProject.analysisType || 'analysis')} 
                      selected={currentStep === 'landUse' || currentStep === 'systemDesign'}
                      disabled={!currentProject.location || !currentProject.analysisType}
                      sx={{
                        borderRadius: 1,
                        mb: 1,
                        '&.Mui-selected': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        },
                      }}
                    >
                      <ListItemIcon>
                        {currentProject.analysisType === 'landUse' ? <LandscapeIcon color="primary" /> : <SettingsIcon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={`3. ${currentProject.analysisType === 'landUse' ? 'Land Use Analysis' : 'System Design'}`} />
                    </ListItem>
                  </List>
                </Paper>
              )}
            </Box>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - 280px)` },
              height: '100%',
              overflow: 'auto',
            }}
          >
            {renderContent()}
          </Box>
        </Box>

        <Dialog open={isNewProjectDialogOpen} onClose={() => setIsNewProjectDialogOpen(false)}>
          <DialogTitle>Create New Project</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              type="text"
              fullWidth
              variant="standard"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsNewProjectDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleCreateProject}>Create</Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={projectMenuAnchor}
          open={Boolean(projectMenuAnchor)}
          onClose={handleProjectMenuClose}
        >
          <MenuItem onClick={handleEditProject}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDuplicateProject}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDeleteProject}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </Menu>
        <Dialog open={isEditProjectDialogOpen} onClose={() => setIsEditProjectDialogOpen(false)}>
          <DialogTitle>Edit Project</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="editName"
              label="Project Name"
              type="text"
              fullWidth
              variant="standard"
              value={editProjectName}
              onChange={(e) => setEditProjectName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditProjectDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveEditProject}>Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
}

export default Hydrosage;