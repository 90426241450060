import { createTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2E8B57', // Sea Green
    },
    secondary: {
      main: '#20b2aa', // Light Sea Green
    },
    background: {
      default: '#f0f8ff', // Alice Blue
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 8,
  },
});

export const Logo = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <svg
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L2 7L12 12L22 7L12 2Z"
        fill="#4caf50"
        stroke="#4caf50"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17L12 22L22 17"
        stroke="#4caf50"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12L12 17L22 12"
        stroke="#4caf50"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <Typography variant="h5" component="span" sx={{ fontWeight: 'bold', letterSpacing: 1 }}>
      Green H<sub>2</sub> AI
    </Typography>
  </Box>
);