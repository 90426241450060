import React, { useState, useMemo } from 'react';
import { Box, Typography, Grid, Paper, Slider } from '@mui/material';
import { alpha } from '@mui/material/styles';
import GenerationChart from './charts/GenerationChart';
import ElectricityFlowChart from './charts/ElectricityFlowChart';
import HydrogenFlowChart from './charts/HydrogenFlowChart';
import StorageGridChart from './charts/StorageGridChart';
import SystemCapacityChart from './charts/SystemCapacityChart';
import AnnualProductionChart from './charts/AnnualProductionChart';
import KeyMetrics from './KeyMetrics';
import SummaryTable from './SummaryTable';

function ResultsDisplay({ results, theme }) {
  const [dateRange, setDateRange] = useState([3624, 3624 + 7 * 24 - 1]);

  const processedData = useMemo(() => {
    if (!results || !results.hourly_data) return [];
    
    const currentYear = new Date().getFullYear();
    const startOfYear = new Date(currentYear, 0, 1);
    
    return results.hourly_data.map((item, index) => ({
      ...item,
      hour: index + 1,
      datetime: new Date(startOfYear.getTime() + index * 3600000)
    }));
  }, [results]);

  const filteredData = useMemo(() => {
    return processedData.slice(dateRange[0], dateRange[1] + 1);
  }, [processedData, dateRange]);

  const handleDateRangeChange = (event, newValue) => {
    setDateRange(newValue);
  };

  const formatSliderLabel = (value) => {
    const date = new Date(new Date().getFullYear(), 0, 1);
    date.setHours(value);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  console.log("Full Results in ResultsDisplay:", JSON.stringify(results, null, 2));

  console.log("Results in ResultsDisplay:", JSON.stringify(results, null, 2));

  if (!results) {
    console.log("No results data available");
    return null;
  }

  // Check if optimal_system exists, if not, use the results object itself
  const optimalSystem = results.optimal_system || results;

  return (
    <Box sx={{ mt: 4, p: 4, bgcolor: alpha(theme.palette.background.paper, 0.8), borderRadius: 4, boxShadow: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, color: theme.palette.primary.main, fontWeight: 'bold' }}>
        Optimization Results
      </Typography>
      
      {Object.keys(optimalSystem).length > 0 ? (
        <SummaryTable data={optimalSystem} theme={theme} />
      ) : (
        <Typography color="error">No optimal system data available</Typography>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>System Capacity</Typography>
            <SystemCapacityChart data={results.optimal_system} theme={theme} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Annual Production</Typography>
            <AnnualProductionChart data={results.annual_production} theme={theme} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <KeyMetrics data={results} theme={theme} />
        </Grid>
      </Grid>

      <Paper elevation={3} sx={{ p: 3, my: 4, borderRadius: 2, bgcolor: alpha(theme.palette.background.paper, 0.9) }}>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.secondary.main, fontWeight: 'medium' }}>
          Select Date Range
        </Typography>
        <Slider
          value={dateRange}
          onChange={handleDateRangeChange}
          valueLabelDisplay="auto"
          valueLabelFormat={formatSliderLabel}
          min={0}
          max={8759}
          sx={{ mt: 4 }}
        />
      </Paper>

      <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2, color: theme.palette.secondary.main, fontWeight: 'medium' }}>
        Hourly Results
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <GenerationChart data={filteredData} theme={theme} />
        </Grid>
        <Grid item xs={12}>
          <ElectricityFlowChart data={filteredData} theme={theme} />
        </Grid>
        <Grid item xs={12}>
          <HydrogenFlowChart data={filteredData} theme={theme} />
        </Grid>
        <Grid item xs={12}>
          <StorageGridChart data={filteredData} theme={theme} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ResultsDisplay;