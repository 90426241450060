import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { alpha } from '@mui/material/styles';
import { CustomTooltip } from './ChartUtils';

const GenerationChart = ({ data, theme }) => {
  const formatXAxis = (tickItem) => {
    return new Date(tickItem).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: theme ? alpha(theme.palette.background.paper, 0.9) : 'inherit' }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme ? theme.palette.secondary.main : 'inherit', fontWeight: 'medium' }}>
        Generation (MW)
      </Typography>
      <Box sx={{ height: 250 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={theme ? alpha(theme.palette.text.secondary, 0.2) : 'inherit'} />
            <XAxis dataKey="datetime" tickFormatter={formatXAxis} stroke={theme ? theme.palette.text.secondary : 'inherit'} />
            <YAxis stroke={theme ? theme.palette.text.secondary : 'inherit'} domain={[0, 'auto']} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="solar_data" name="Solar" stroke="#ffd54f" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="wind_data" name="Wind" stroke="#4fc3f7" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="curtailment" name="Curtailment" stroke="#ff7043" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default GenerationChart;