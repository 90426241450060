import React, { createContext, useContext, useState } from 'react';

const ProjectLocationContext = createContext();

export function ProjectLocationProvider({ children }) {
  const [projectLocation, setProjectLocation] = useState(null);

  return (
    <ProjectLocationContext.Provider value={{ projectLocation, setProjectLocation }}>
      {children}
    </ProjectLocationContext.Provider>
  );
}

export function useProjectLocation() {
  return useContext(ProjectLocationContext);
}