import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Hydrosage from './Hydrosage';
import { ProjectLocationProvider } from './ProjectLocationContext';

function App() {
  return (
    <ProjectLocationProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/hydrosage" element={<Hydrosage />} />
        </Routes>
      </Router>
    </ProjectLocationProvider>
  );
}

export default App;