import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Paper, Button, CircularProgress, Switch, FormControlLabel, Slider, IconButton, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useProjectLocation } from './ProjectLocationContext';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const MAPBOX_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11';

const LandUseAnalyser = () => {
  const { projectLocation } = useProjectLocation();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [mapError, setMapError] = useState(null);
  const [distance, setDistance] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [showLegend, setShowLegend] = useState(false);
  const [showTransmissionLines, setShowTransmissionLines] = useState(true);
  const [showNaturalGasPipelines, setShowNaturalGasPipelines] = useState(true);
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  useEffect(() => {
    if (!MAPBOX_TOKEN) {
      console.error("LandUseAnalyser: MAPBOX_TOKEN is not set");
      setMapError("Mapbox token is not set");
      return;
    }

    console.log("MAPBOX_TOKEN:", MAPBOX_TOKEN);

    if (mapContainer.current && !map.current && projectLocation) {
      mapboxgl.accessToken = MAPBOX_TOKEN;
      try {
        const newMap = new mapboxgl.Map({
          container: mapContainer.current,
          style: MAPBOX_STYLE,
          center: [parseFloat(projectLocation.lng), parseFloat(projectLocation.lat)],
          zoom: 8
        });

        newMap.on('load', () => {
          console.log("Map loaded");
          setMapInitialized(true);
          map.current = newMap;

          addMapLayers(newMap);
        });

        newMap.on('error', (e) => {
          console.error("LandUseAnalyser: Map error:", e);
          setMapError(`Map error: ${e.error ? e.error.message : 'Unknown error'}`);
        });
      } catch (error) {
        console.error("LandUseAnalyser: Error initializing map:", error);
        setMapError(`Error initializing map: ${error.message}`);
      }
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
        setMapInitialized(false);
      }
    };
  }, [projectLocation, MAPBOX_TOKEN]);

  const addMapLayers = (mapInstance) => {
    // Add transmission lines layer
    mapInstance.addSource('transmission-lines-source', {
      'type': 'vector',
      'url': 'mapbox://greenh2.3mb54qce'
    });

    mapInstance.addLayer({
      'id': 'transmission-lines',
      'type': 'line',
      'source': 'transmission-lines-source',
      'source-layer': 'ez_gis-b5arfn',
      'layout': {
        'visibility': 'visible'
      },
      'paint': {
        'line-color': 'rgba(0, 0, 0, 0.8)', // Black with 80% opacity
        'line-width': 2
      }
    });

    // Add natural gas pipelines layer
    mapInstance.addSource('natural-gas-pipelines-source', {
      'type': 'vector',
      'url': 'mapbox://greenh2.c49v2v6j'
    });

    mapInstance.addLayer({
      'id': 'natural-gas-pipelines',
      'type': 'line',
      'source': 'natural-gas-pipelines-source',
      'source-layer': 'NaturalGas_InterIntrastate_Pi-9405qc',
      'layout': {
        'visibility': 'visible'
      },
      'paint': {
        'line-color': 'hsl(241, 67%, 44%)',
        'line-width': 2
      }
    });

    new mapboxgl.Marker()
      .setLngLat([parseFloat(projectLocation.lng), parseFloat(projectLocation.lat)])
      .addTo(mapInstance);
  };

  const handleAnalysis = async () => {
    if (!mapInitialized || !map.current) {
      console.error("Map not initialized");
      setApiError("Map not initialized. Please try again.");
      return;
    }

    setIsLoading(true);
    setApiError(null);
    setShowLegend(false);

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.greenh2.ai/land-usage'
      : '/land-usage';  // This will use the proxy in development

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          lat: parseFloat(projectLocation.lat),
          lon: parseFloat(projectLocation.lng),
          range: distance * 1000
        })
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      console.log("API response:", data);

      if (!data.visual_gdf_geojson) {
        throw new Error('visual_gdf_geojson not found in API response');
      }

      const geoJsonUrl = data.visual_gdf_geojson.replace(
        's3://greenh2-dev-bucket/',
        'https://greenh2-dev-bucket.s3.us-west-2.amazonaws.com/'
      );

      const geoJsonResponse = await fetch(geoJsonUrl);
      if (!geoJsonResponse.ok) {
        throw new Error(`HTTP error! status: ${geoJsonResponse.status}`);
      }
      const geoJsonData = await geoJsonResponse.json();

      if (map.current.getSource('land-usage')) {
        map.current.removeLayer('land-usage-layer');
        map.current.removeSource('land-usage');
      }

      map.current.addSource('land-usage', {
        type: 'geojson',
        data: geoJsonData
      });

      map.current.addLayer({
        id: 'land-usage-layer',
        type: 'fill',
        source: 'land-usage',
        paint: {
          'fill-color': [
            'match',
            ['get', 'polygon_type'],
            'Sensitive Habitat', 'rgba(0, 128, 0, 0.744)',  // Green with 74.4% opacity
            'Permanent Water/Ice Covered', 'rgba(0, 0, 255, 0.744)',  // Blue with 74.4% opacity
            'Urban Area', 'rgba(255, 0, 0, 0.744)',  // Red with 74.4% opacity
            'Unfavorable Topography', 'rgba(255, 255, 0, 0.744)',  // Yellow with 74.4% opacity
            'Buildable', 'rgba(128, 0, 128, 0.744)',  // Purple with 74.4% opacity
            'rgba(128, 128, 128, 0.744)'  // Default color (gray) with 74.4% opacity
          ],
          'fill-opacity': 0.744
        }
      });

      const bounds = new mapboxgl.LngLatBounds();
      geoJsonData.features.forEach(feature => {
        if (feature.geometry.type === 'Polygon') {
          feature.geometry.coordinates[0].forEach(coord => {
            bounds.extend(coord);
          });
        }
      });
      map.current.fitBounds(bounds, { padding: 50 });

      setShowLegend(true);
    } catch (error) {
      console.error("Error in land usage analysis:", error);
      setApiError(`Error in analysis: ${error.message}`);
      setShowLegend(false);
    } finally {
      setIsLoading(false);
    }
  };

  const legendItems = [
    { type: 'Sensitive Habitat', color: 'rgba(0, 128, 0, 0.744)', isLine: false },
    { type: 'Permanent Water/Ice Covered', color: 'rgba(0, 0, 255, 0.744)', isLine: false },
    { type: 'Urban Area', color: 'rgba(255, 0, 0, 0.744)', isLine: false },
    { type: 'Unfavorable Topography', color: 'rgba(255, 255, 0, 0.744)', isLine: false },
    { type: 'Buildable', color: 'rgba(128, 0, 128, 0.744)', isLine: false },
    { type: 'Transmission Lines', color: 'rgba(0, 0, 0, 0.8)', isLine: true },
    { type: 'Natural Gas Pipelines', color: 'hsl(241, 67%, 44%)', isLine: true },
  ];

  const handleLayerToggle = (layerId) => {
    if (map.current) {
      const visibility = map.current.getLayoutProperty(layerId, 'visibility');
      const newVisibility = visibility === 'visible' ? 'none' : 'visible';
      map.current.setLayoutProperty(layerId, 'visibility', newVisibility);
      
      if (layerId === 'transmission-lines') {
        setShowTransmissionLines(newVisibility === 'visible');
      } else if (layerId === 'natural-gas-pipelines') {
        setShowNaturalGasPipelines(newVisibility === 'visible');
      }
    }
  };

  const LayerControl = ({ label, checked, onChange, color }) => (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          color="primary"
          size="small"
        />
      }
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: 20, height: 2, backgroundColor: color, mr: 1 }} />
          <Typography variant="body2">{label}</Typography>
        </Box>
      }
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 1,
        '& .MuiFormControlLabel-label': { 
          display: 'flex', 
          alignItems: 'center',
          flexGrow: 1,
          color: 'black',
        }
      }}
    />
  );

  if (!projectLocation) {
    return <Typography>No project location selected.</Typography>;
  }

  return (
    <Box sx={{ 
      height: 'calc(100vh - 64px)', // Adjust this value based on your app's header height
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden',
      p: 2, // Add some padding to the container
    }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2E8B57', mb: 1 }}>Land Use Analysis</Typography>
      <Box sx={{ 
        flexGrow: 1, 
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: 'calc(100% - 40px)', // Reduce the height slightly
      }}>
        <Box 
          ref={mapContainer} 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: '16px',
            overflow: 'hidden',
          }}
        />
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: 10,
            left: isPanelOpen ? 10 : -280,
            width: 280,
            maxHeight: 'calc(100% - 20px)',
            overflowY: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '8px',
            transition: 'left 0.3s',
            zIndex: 2,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2E8B57' }}>Estimate Site Land Use</Typography>
            <Box sx={{ mb: 2 }}>
              <Typography gutterBottom>Buffer Distance (km):</Typography>
              <Slider
                value={distance}
                onChange={(e, newValue) => setDistance(newValue)}
                min={1}
                max={15}
                step={1}
                valueLabelDisplay="auto"
              />
              <Typography variant="body2" align="right">{distance} km</Typography>
            </Box>
            <Button 
              variant="contained" 
              onClick={handleAnalysis}
              disabled={isLoading}
              fullWidth
              sx={{ 
                mt: 2,
                mb: 2,
                backgroundColor: '#2E8B57',
                '&:hover': {
                  backgroundColor: '#1b5e20',
                },
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Analyze'}
            </Button>
            {apiError && <Typography color="error" sx={{ mt: 2, mb: 2 }}>{apiError}</Typography>}
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#2E8B57' }}>Add Layers</Typography>
            <LayerControl
              label="Transmission Lines"
              checked={showTransmissionLines}
              onChange={() => handleLayerToggle('transmission-lines')}
              color="hsl(0, 84%, 54%)"
            />
            <LayerControl
              label="Natural Gas Pipelines"
              checked={showNaturalGasPipelines}
              onChange={() => handleLayerToggle('natural-gas-pipelines')}
              color="hsl(241, 67%, 44%)"
            />
          </Box>
        </Paper>
        <IconButton
          onClick={() => setIsPanelOpen(!isPanelOpen)}
          sx={{
            position: 'absolute',
            top: 10,
            left: isPanelOpen ? 290 : 10,
            backgroundColor: 'white',
            '&:hover': { backgroundColor: '#f0f0f0' },
            transition: 'left 0.3s',
            zIndex: 3,
          }}
        >
          {isPanelOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        {showLegend && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              right: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: 2,
              borderRadius: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>Legend</Typography>
            {legendItems.map(item => (
              <Box key={item.type} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {item.isLine ? (
                  <Box sx={{ width: 20, height: 2, backgroundColor: item.color, mr: 1 }} />
                ) : (
                  <Box sx={{ width: 20, height: 20, backgroundColor: item.color, mr: 1 }} />
                )}
                <Typography variant="caption">{item.type}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {!mapInitialized && !mapError && <Typography>Loading map...</Typography>}
      {mapError && <Typography color="error">{mapError}</Typography>}
    </Box>
  );
};

export default LandUseAnalyser;