import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';

function KeyMetrics({ data, theme }) {
  if (!data || !data.optimal_system || !data.lcoh) return null;

  const { optimal_system, lcoh } = data;

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Key Metrics</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography>LCOH: ${lcoh.toFixed(2)}/kg</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Total CAPEX: ${optimal_system.total_capex?.toFixed(2) || 'N/A'} million</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Annual OPEX: ${optimal_system.annual_opex?.toFixed(2) || 'N/A'} million</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Capacity Factor: {(optimal_system.capacity_factor * 100)?.toFixed(2) || 'N/A'}%</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default KeyMetrics;