import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { alpha } from '@mui/material/styles';
import { CustomTooltip, formatXAxis } from './ChartUtils';

const StorageGridChart = ({ data, theme }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2, bgcolor: theme ? alpha(theme.palette.background.paper, 0.9) : 'inherit' }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme ? theme.palette.secondary.main : 'inherit', fontWeight: 'medium' }}>
        Storage & Grid
      </Typography>
      <Box sx={{ height: 250 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke={theme ? alpha(theme.palette.text.secondary, 0.2) : 'inherit'} />
            <XAxis dataKey="datetime" tickFormatter={formatXAxis} stroke={theme ? theme.palette.text.secondary : 'inherit'} />
            <YAxis stroke={theme ? theme.palette.text.secondary : 'inherit'} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="hydrogen_stored" name="H2 Stored (kg)" stroke="#8d6e63" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="soc" name="Battery SOC (%)" stroke="#78909c" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="grid_power_buy" name="Grid Power (MW)" stroke="#5c6bc0" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default StorageGridChart;