export const formatXAxis = (tickItem) => {
  return new Date(tickItem).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`${new Date(label).toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric' })}`}</p>
        {payload.map((pld, index) => (
          <p key={index} style={{ color: pld.color }}>
            {`${pld.name}: ${pld.value?.toFixed(2) ?? 'N/A'}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};