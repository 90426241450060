import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

const Logo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      animation: 'rotate 10s linear infinite, pulse 2s ease-in-out infinite',
    }}
  >
    <path
      d="M12 2L2 7L12 12L22 7L12 2Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 17L12 22L22 17"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12L12 17L22 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const HomePage = () => {
  return (
    <div className="home-page">
      <header>
        <div className="logo">
          <Logo />
          <span>Green H<span className="rotating-2">2</span> AI</span>
        </div>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li className="dropdown">
              <a href="#" className="dropbtn">Products</a>
              <div className="dropdown-content">
                <Link to="/hydrosage">Hydrosage</Link>
                <Link to="/power-plant-optimizer">Power Plant Optimizer</Link>
              </div>
            </li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <section id="home" className="hero-content">
          <h1>Revolutionizing Green Hydrogen Development</h1>
          <p>Empower your sustainable energy projects with cutting-edge AI-driven solutions.</p>
          <Link to="/hydrosage" className="cta-button">Explore Hydrosage</Link>
        </section>
        <section id="features" className="features-section">
          <h2>Hydrosage Product Features</h2>
          <div className="feature-cards">
            <div className="feature-card">
              <i className="fas fa-map-marked-alt"></i>
              <h3>Location Intelligence</h3>
              <p>Identify optimal sites for your green hydrogen facilities.</p>
            </div>
            <div className="feature-card">
              <i className="fas fa-cogs"></i>
              <h3>Project Optimization</h3>
              <p>Fine-tune your setup for maximum efficiency and output.</p>
            </div>
            <div className="feature-card">
              <i className="fas fa-chart-line"></i>
              <h3>ROI Projection</h3>
              <p>Forecast returns and ensure project viability with confidence.</p>
            </div>
          </div>
        </section>
        <section id="contact">
          <h2>Contact Us</h2>
          <form id="contact-form">
            <input type="text" name="name" placeholder="Your Name" required />
            <input type="text" name="company" placeholder="Company Name" required />
            <input type="email" name="email" placeholder="Email Address" required />
            <input type="tel" name="phone" placeholder="Phone Number" />
            <textarea name="message" placeholder="Your Message" required></textarea>
            <button type="submit" className="cta-button">Send Message</button>
          </form>
        </section>
      </main>
      <footer>
        <div className="footer-content">
          <p>&copy; 2024 GreenH2 AI. All rights reserved.</p>
          <div className="social-icons">
            <a href="#" aria-label="LinkedIn"><i className="fab fa-linkedin"></i></a>
            <a href="#" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
            <a href="#" aria-label="GitHub"><i className="fab fa-github"></i></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;