import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function AnnualProductionChart({ data, theme }) {
  if (!data) return null;

  const chartData = {
    labels: ['Wind', 'Solar', 'Grid'],
    datasets: [{
      data: [
        data.wind_production || 0,
        data.solar_production || 0,
        data.grid_consumption || 0,
      ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.6)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(75, 192, 192, 0.6)',
      ],
    }],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'MWh'
        }
      }
    }
  };

  return <Bar data={chartData} options={options} />;
}

export default AnnualProductionChart;