import React, { useState } from 'react';
import { Box, Typography, Paper, TextField, Button, CircularProgress, Grid, Card, CardContent, CardHeader } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ResultsDisplay from './components/ResultsDisplay';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// Make sure to set your Mapbox access token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const ColorfulCard = styled(Card)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: `0 8px 16px 0 ${alpha(theme.palette.secondary.main, 0.2)}`,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: alpha(theme.palette.text.primary, 0.3),
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover fieldset': {
      borderColor: alpha(theme.palette.text.primary, 0.5),
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: alpha(theme.palette.text.primary, 0.7),
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
}));

const PowerPlantOptimizer = ({ initialFormData, selectedLocation, theme }) => {
  const [formData, setFormData] = useState({
    year: 2025,
    onshore_wind_cost: 1300,
    solar_pv_cost: 800,
    battery_storage_cost: 200,
    electrolyzer_cost: 600,
    h2_storage_cost: 500,
    debt_equity_ratio: 70,
    equity_cost: 10,
    debt_cost: 5,
    hydrogen_demand: 1000,
    grid_power_limit: 5,
    ...initialFormData
  });
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: parseFloat(value) || 0
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.greenh2.ai/optimize'
      : '/optimize';  // This will use the proxy in development
    const timestamp = new Date().getTime();
    const fullApiUrl = `${apiUrl}?t=${timestamp}`;

    const requestData = {
      ...formData,
      latitude: parseFloat(selectedLocation.lat),
      longitude: parseFloat(selectedLocation.lng),
    };

    try {
      const response = await fetch(fullApiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error in API call:', error);
      setError(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
        System Design Optimizer
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6} lg={3}>
            <ColorfulCard>
              <CardHeader title="Year" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }} />
              <CardContent>
                <StyledTextField
                  fullWidth
                  name="year"
                  label="Year"
                  type="number"
                  value={formData.year}
                  onChange={handleInputChange}
                />
              </CardContent>
            </ColorfulCard>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ColorfulCard>
              <CardHeader title="Capital Costs ($/kW)" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }} />
              <CardContent>
                {['onshore_wind_cost', 'solar_pv_cost', 'battery_storage_cost', 'electrolyzer_cost', 'h2_storage_cost'].map((cost) => (
                  <StyledTextField
                    key={cost}
                    fullWidth
                    name={cost}
                    label={cost.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    type="number"
                    value={formData[cost]}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                ))}
              </CardContent>
            </ColorfulCard>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ColorfulCard>
              <CardHeader title="Financing Costs" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }} />
              <CardContent>
                {['debt_equity_ratio', 'equity_cost', 'debt_cost'].map((cost) => (
                  <StyledTextField
                    key={cost}
                    fullWidth
                    name={cost}
                    label={cost.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    type="number"
                    value={formData[cost]}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                ))}
              </CardContent>
            </ColorfulCard>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ColorfulCard>
              <CardHeader title="Optimization Variables" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }} />
              <CardContent>
                <StyledTextField
                  fullWidth
                  name="hydrogen_demand"
                  label="Hydrogen Demand (kg/day)"
                  type="number"
                  value={formData.hydrogen_demand}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <StyledTextField
                  fullWidth
                  name="grid_power_limit"
                  label="Grid Power Limit (MW)"
                  type="number"
                  value={formData.grid_power_limit}
                  onChange={handleInputChange}
                />
              </CardContent>
            </ColorfulCard>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            size="large"
            sx={{ 
              borderRadius: theme.shape.borderRadius * 2,
              padding: '10px 30px',
              fontWeight: 'bold',
              boxShadow: `0 4px 6px ${alpha(theme.palette.secondary.main, 0.25)}`,
              '&:hover': {
                boxShadow: `0 6px 8px ${alpha(theme.palette.secondary.main, 0.3)}`,
              }
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Optimize'}
          </Button>
        </Box>
      </form>
      {error && <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>{error}</Typography>}
      {results && (
        <Paper elevation={3} sx={{ p: 3, mt: 3, borderRadius: theme.shape.borderRadius * 2 }}>
          <ResultsDisplay results={results} theme={theme} />
        </Paper>
      )}
    </Box>
  );
};

export default PowerPlantOptimizer;
